const { Environment, Network, RecordSource, Store } = require('relay-runtime')

const source = new RecordSource()
const store = new Store(source)
const handlerProvider = null
const ADDITIONAL_HEADERS = {}

const LIVE_ENV = 'cdn.realworks.nl'
const LIVE_ENV_API = 'https://graphql-api-crm-internal.realworks.nl/graphql'
const HEAD_ENV = 'cdn-crm-internal-test.realworks.nl'
const HEAD_ENV_API = 'https://graphql-api-crm-internal-test.realworks.nl/graphql'

const KUBERNETES_ENV = 'head-cms-client.prod.in.realworks.nl'
const KUBERNETES_ENV_API = 'https://head-cms-api.prod.in.realworks.nl/graphql'

const BACKUP_ENV = 'cdn-crm-internal-backup.realworks.nl'
const BACKUP_ENV_API = 'https://graphql-api-crm-internal-backup.realworks.nl/graphql'
const BACKUP_TEST_ENV = 'cdn-crm-internal-test-backup.realworks.nl'
const BACKUP_TEST_ENV_API = 'https://graphql-api-crm-internal-test-backup.realworks.nl/graphql'

const DEV_ENV = 'cms-client.local.realworks.nl'
const DEV_ENV_API = 'http://cms-api.local.realworks.nl:8082/graphql'

const DC1_ENV = 'cdn-crm-internal-dc1.realworks.nl'
const DC1_ENV_API = 'https://graphql-api-crm-internal-dc1.realworks.nl/graphql'

const DC2_ENV = 'cdn-crm-internal-dc2.realworks.nl'
const DC2_ENV_API = 'https://graphql-api-crm-internal-dc2.realworks.nl/graphql'

const DC1_TEST_ENV = 'cdn-crm-internal-test-dc1.realworks.nl'
const DC1_TEST_ENV_API = 'https://graphql-api-crm-internal-test-dc1.realworks.nl/graphql'

const DC2_TEST_ENV = 'cdn-crm-internal-test-dc2.realworks.nl'
const DC2_TEST_ENV_API = 'https://graphql-api-crm-internal-test-dc2.realworks.nl/graphql'

const API_SERVER = getApiServer()

function getApiServer() {
	const { host } = document.location

	switch (host) {
		case LIVE_ENV:
			return LIVE_ENV_API
		case HEAD_ENV:
			return HEAD_ENV_API
		case KUBERNETES_ENV:
			return KUBERNETES_ENV_API
		case BACKUP_ENV:
			return BACKUP_ENV_API
		case BACKUP_TEST_ENV:
			return BACKUP_TEST_ENV_API
		case DC1_ENV:
			return DC1_ENV_API
		case DC2_ENV:
			return DC2_ENV_API
		case DC1_TEST_ENV:
			return DC1_TEST_ENV_API
		case DC2_TEST_ENV:
			return DC2_TEST_ENV_API
	}

	if (host === DEV_ENV || host.startsWith(DEV_ENV + ':')) {
		return DEV_ENV_API
	}

	throw new Error('Not able to determine environment')
}

// Define a function that fetches the results of an operation (query/mutation/etc)
// and returns its results as a Promise:
async function fetchQuery(operation, variables, cacheConfig, uploadables) {
	const request = {
		method: 'POST',
		headers: {
			...ADDITIONAL_HEADERS,
		},
	}

	if (uploadables) {
		if (!window.FormData) {
			throw new Error('Uploading files without `FormData` not supported.')
		}

		const formData = new FormData()
		formData.append('query', operation.text)
		formData.append('variables', JSON.stringify(variables))

		Object.keys(uploadables).forEach((key) => {
			if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
				formData.append(key, uploadables[key])
			}
		})

		request.body = formData
	} else {
		request.headers['Content-Type'] = 'application/json'
		request.body = JSON.stringify({
			query: operation.text,
			variables,
		})
	}

	const response = await fetch(API_SERVER, request)
	try {
		const json = await response.json()
		//console.log(json);
		return json
	} catch (e) {
		console.log(e)
	}
}

// Create a network layer from the fetch function
const network = Network.create(fetchQuery)

// Create an environment using this network:
export default new Environment({
	handlerProvider, // Can omit.
	network,
	store,
})

export const setAuthorizationToken = (token) => {
	ADDITIONAL_HEADERS['Authorization'] = `Bearer ${token}`
}
